
/* eslint-disable */

import { ref, reactive, onMounted, provide } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import router from "@/router";

export default defineComponent({
  components: { CfInputBox, VForm: Form },
  setup() {
    const model = CloudFun.current?.model;

    const user = ref({} as any);

    const getInfo = async () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getAllow = async () => {
      return model
        ?.dispatch("user/allowPwd")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const changePwd = async (data: any) => {
      return model
        ?.dispatch("user/changePwd", {
          pwd: data.oldpwd,
          newPwd: data.pwd
        })
        .then(response => {
          if (response.status) {
            CloudFun.send("info", {
              subject: "變更成功",
              content: "系統將於五秒後登出，請重新登入！"
            });
            setTimeout(() => {
              router.push("/login");
            }, 5000);
          } else {
            CloudFun.send("error", {
              subject: "變更失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "變更失敗",
            content: error.message
          });
        });
    };

    const pwdData = ref({
      oldpwd: "",
      pwd: "",
      pwd2: ""
    });

    const submit = async (data: any) => {
      console.log("🚀 ~ file: Main.vue ~ line 217 ~ submit ~ data", data);
      //return;
      const allow = await getAllow();
      if (allow && allow.isAllowedChangePwd) {
        await changePwd(data);
      }
    };

    const validationSchema = Yup.object().shape({
      oldpwd: Yup.string()
        .min(8, "不得小於8位元")
        .max(20, "不得大於20位元")
        .required("必要欄位"),
      pwd: Yup.string()
        .min(8, "不得小於8位元")
        .max(20, "不得大於20位元")
        .required("必要欄位"),
      pwd2: Yup.string()
        .min(8, "不得小於8位元")
        .max(20, "不得大於20位元")
        .oneOf([Yup.ref("pwd")], "請輸入一致的密碼")
        .required("必要欄位")
    });

    onMounted(async () => {
      user.value = await getInfo();

      // pwdData.oldpwd = "";
      // pwdData.pwd = "";
      // pwdData.pwd2 = "";
      //console.log("user data", user.value);
    });

    return {
      getInfo,
      user,
      pwdData,
      submit,
      validationSchema
    };
  },
  methods: {}
});
