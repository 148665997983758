<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">密碼管理</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-10 mt-10">
      <div v-if="user?.hasPwd">
        <VForm
          :validation-schema="validationSchema"
          :initial-values="pwdData"
          @submit="submit"
        >
          <div class=" sm:flex mb-8">
            <!-- <div class=" sm:w-40 sm:text-right text-base mr-5">舊密碼</div> -->
            <!-- <div class=" w-full text-base text-theme-1">
            <input
              type="password"
              class=" w-full sm:w-80 intro-x login__input input input--lg border border-gray-300 block"
              placeholder="舊密碼"
              v-model="password"
            />
            
          </div> -->
            <div class=" w-full lg:w-1/3">
              <CfInputBox
                label="舊密碼"
                name="oldpwd"
                placeholder="舊密碼"
                type="password"
                :modelValue="pwdData.oldpwd"
                @update:modelValue="
                  val => {
                    pwdData.oldpwd = val;
                  }
                "
                :showMessage="true"
              ></CfInputBox>
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <!-- <div class=" sm:w-40 sm:text-right text-base mr-5">新密碼</div>
          <div class=" sm:flex w-full text-base text-theme-1">
            <input
              type="password"
              class=" w-full sm:w-80 intro-x login__input input input--lg border border-gray-300 block"
              placeholder="新密碼"
              v-model="password"
            />
            <div class=" flex items-center ml-3 text-theme-9">
              <CheckCircleIcon class="w-4 h-4 mr-2" />此密碼可使用
            </div>
            <div class=" flex items-center ml-3 text-theme-6">
								<XCircleIcon class="w-4 h-4 mr-2" />此密碼不可使用
							</div>
          </div> -->
            <div class=" w-full lg:w-1/3">
              <CfInputBox
                label="新密碼"
                name="pwd"
                placeholder="新密碼"
                type="password"
                :modelValue="pwd"
                @update:modelValue="
                  val => {
                    pwd = val;
                  }
                "
                :showMessage="true"
              ></CfInputBox>
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <!--<div class=" sm:w-40 sm:text-right text-base mr-5">
            再次輸入新密碼
          </div>
          <div class=" sm:flex w-full text-base text-theme-1">
            <input
              type="password"
              class=" w-full sm:w-80 intro-x login__input input input--lg border border-gray-300 block"
              placeholder="再次輸入新密碼"
              v-model="password"
            />
             <div class=" flex items-center ml-3 text-theme-9">
              <CheckCircleIcon class="w-4 h-4 mr-2" />此密碼可使用
            </div> 
             <div class=" flex items-center ml-3 text-theme-6">
								<XCircleIcon class="w-4 h-4 mr-2" />此密碼不可使用
							</div> 
          </div>-->
            <div class=" w-full lg:w-1/3">
              <CfInputBox
                label="再次輸入新密碼"
                name="pwd2"
                placeholder="再次輸入新密碼"
                type="password"
                :modelValue="pwd2"
                @update:modelValue="
                  val => {
                    pwd2 = val;
                  }
                "
                :showMessage="true"
              ></CfInputBox>
            </div>
          </div>
          <div class=" text-center">
            <button
              type="submit"
              class="button flex items-center justify-center button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0 ml-3"
            >
              <CheckIcon class="mr-2 w-4 h-4" />
              儲存
            </button>
          </div>
        </VForm>
      </div>

      <div v-if="!user?.hasPwd">
        <div>您目前使用社群登入方式登入，無此功能</div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { ref, reactive, onMounted, provide } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import router from "@/router";

export default defineComponent({
  components: { CfInputBox, VForm: Form },
  setup() {
    const model = CloudFun.current?.model;

    const user = ref({} as any);

    const getInfo = async () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getAllow = async () => {
      return model
        ?.dispatch("user/allowPwd")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const changePwd = async (data: any) => {
      return model
        ?.dispatch("user/changePwd", {
          pwd: data.oldpwd,
          newPwd: data.pwd
        })
        .then(response => {
          if (response.status) {
            CloudFun.send("info", {
              subject: "變更成功",
              content: "系統將於五秒後登出，請重新登入！"
            });
            setTimeout(() => {
              router.push("/login");
            }, 5000);
          } else {
            CloudFun.send("error", {
              subject: "變更失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "變更失敗",
            content: error.message
          });
        });
    };

    const pwdData = ref({
      oldpwd: "",
      pwd: "",
      pwd2: ""
    });

    const submit = async (data: any) => {
      console.log("🚀 ~ file: Main.vue ~ line 217 ~ submit ~ data", data);
      //return;
      const allow = await getAllow();
      if (allow && allow.isAllowedChangePwd) {
        await changePwd(data);
      }
    };

    const validationSchema = Yup.object().shape({
      oldpwd: Yup.string()
        .min(8, "不得小於8位元")
        .max(20, "不得大於20位元")
        .required("必要欄位"),
      pwd: Yup.string()
        .min(8, "不得小於8位元")
        .max(20, "不得大於20位元")
        .required("必要欄位"),
      pwd2: Yup.string()
        .min(8, "不得小於8位元")
        .max(20, "不得大於20位元")
        .oneOf([Yup.ref("pwd")], "請輸入一致的密碼")
        .required("必要欄位")
    });

    onMounted(async () => {
      user.value = await getInfo();

      // pwdData.oldpwd = "";
      // pwdData.pwd = "";
      // pwdData.pwd2 = "";
      //console.log("user data", user.value);
    });

    return {
      getInfo,
      user,
      pwdData,
      submit,
      validationSchema
    };
  },
  methods: {}
});
</script>
